import { UpgradeModalComponent } from './../upgrade-modal/upgrade-modal.component';
import { Title } from '@angular/platform-browser';
import { ContratoService } from './../../../shared/services/contrato.service';
import { ConfigConst } from 'src/app/core/util/config.const';
import { DashboardService } from './dashboard.service';
import { GoogleAnalyticsService } from './../../../shared/services/googleanalytics.service';
import { Users } from 'src/app/shared/model/users.model';
import { CustomValidators } from 'src/app/shared/validators/validators';
import { ConfirmDialogComponent } from './../../../shared/components/confirm-dialog/confirm-dialog.component';
import { PagamentoService } from './../../../shared/services/pagamento.service';
import { AdicionarcontabilidadeComponent } from './../contabilidades/components/adicionarcontabilidade/adicionarcontabilidade.component';
import { GestorstorageService } from './../../../shared/services/gestorstorage.service';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EmaloteService } from 'src/app/shared/services/emalote.service';
import { UserUtil } from 'src/app/core/util/user.util';
import { DialogModalComponent } from 'src/app/shared/components/dialog-modal/dialog-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { EventService } from 'src/app/shared/services/event.service';
import { GestorUsuarioService } from 'src/app/shared/services/gestor-usuario.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import * as _moment from 'moment';
import * as $ from 'jquery';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AtualizarcontabilidadeComponent } from '../contabilidades/components/atualizarcontabilidade/atualizarcontabilidade.component';
import { GestorService } from 'src/app/shared/services/gestor.service';
import { HelpDialogComponent } from 'src/app/shared/components/help-dialog/help-dialog.component';
import { MatMenuTrigger } from '@angular/material/menu';

import {
  faScaleBalanced,
  faCheckDouble,
} from '@fortawesome/free-solid-svg-icons';
import { setTimeout } from 'timers';

const moment = _moment;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
  @ViewChild('menuGigasTrigger') menuGigasTrigger: MatMenuTrigger;
  @ViewChild('menuNotificTrigger') menuNotific: MatMenuTrigger;

  options = new FormControl();
  selectedGestor: string = ConfigConst.SelecionarumaContabilidade;
  statusFilter: Array<number> = [];
  gestores: Array<string> = [];
  gestoresJson: Array<string> = [];
  gestorToWork: string = '';
  fullGestor: string = '';
  userUtil = new UserUtil();
  showPathButtons: boolean = false;
  showNavBarMenu: boolean = false;
  showNavbarMenulateral: boolean = false;
  controlUrl: boolean = false;
  nivel: boolean = false;
  usuario = this.userUtil.getEmailLogin();
  gestorName: string = '';
  UserUtil = new UserUtil();
  users = new Users();
  imp: boolean = false;
  loading: boolean = false;
  compartilhado: boolean = false;
  isSustentacao: boolean = false;
  isTributario: boolean = false;
  isDemo: boolean = false;
  isGestorDemo: boolean = false;
  isProd: boolean = false;
  modulosDisponiveis = ['1', '2', '3', '4', '5'];

  manutencao: boolean = true;

  notificacoesNovas: number = 0;
  notificacoesNovasVisualizadas: boolean = true;

  dataPagamento: string;
  pagamentoPendente: boolean = false;
  dataFalhaPagamento: boolean = false;
  dataInadinplencia: boolean = false;
  urlmeus = '/' + ConfigConst.rotaminhascontabilidades;
  MinhasContabilidades = ConfigConst.MinhasContabilidades;
  daContabilidade = ConfigConst.daContabilidade;
  urlusuario = '/' + ConfigConst.rotausuariocontabilidade;
  contabilidade = ConfigConst.contabilidade;

  minimize: boolean = true;

  faScaleBalanced = faScaleBalanced;
  faCheckDouble = faCheckDouble;

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    public dialog: MatDialog,
    private titleService: Title,
    public eventComponent: EventService,
    private gestorUsuarioService: GestorUsuarioService,
    private messageService: MessageService,
    private route: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private gestorstorageService: GestorstorageService,
    private GestorUsuarioService: GestorUsuarioService,
    private PagamentoService: PagamentoService,
    public gestorStorageService: GestorstorageService,
    private ContratoService: ContratoService,
    @Inject(DOCUMENT) private document: Document,
    private MessageService: MessageService,
    private GestorService: GestorService,
    private EmaloteService: EmaloteService
  ) {
    this.possuiSegregacao = !!ConfigConst.modulo.segregacao;

    this.route.events.subscribe((e) => {
      this.validMenu();
    });

    this.isProd = ConfigConst.Ambiente == 'prod';

    if (this.isValidRoute()) {
      this.showNavBarMenu = this.authService.usuarioEstaAutenticado();
      this.MetricasUpload();
    }
  }

  vencimento_trial = 'indefinido';
  trial: boolean = false;
  cancelamento = '';
  contratar: boolean = false;
  pagar: boolean = false;
  closed: boolean = true;
  margin = 'margin-left:0px!important';
  imposto = 0;
  host = window.location.hostname;
  pathName = window.location.pathname;
  tag: boolean = false;
  days: number;
  continue: boolean = false;
  classic: boolean = false;

  metrica = {};
  CTE;
  NFC;
  NFE;
  NFS;
  SAT;
  Inut;
  valueCTE;
  valueNFC;
  valueNFE;
  valueNFS;
  valueSAT;
  valueInut;
  KBTotal;
  NFSCapturadas: number = 0;
  ProdutosRevisados: number = 0;
  ProdutosRevisadosContratado: number = 0;
  DataHoraUltimaAtualizacao: string = '';
  Descricao: string;
  ProdutosRevisadosMes: number;
  ProdutosRevisadosIA: number;
  ProdutosRevisadosTime: number;

  pathAuditoria: string[] = ['/auditoria', '/analise-consolidada'];

  isImpostocerto: boolean = false;
  auditoria: boolean = false;
  revisao: boolean = false;
  consulta: boolean = false;
  segregacao: boolean = false;
  recuperacao: boolean = false;
  relatorios: boolean = false;
  usuarioproibido: boolean = true;
  emalote: boolean = false;
  iconnotifica: string = 'notifications_none';

  possuiSegregacao: boolean = false;

  tituloBoxModuloAuditoria = ConfigConst.tituloBoxModuloAuditoria;
  tituloBoxModuloSegregacao = ConfigConst.tituloBoxModuloSegregacao;
  tituloBoxModuloConsulta = ConfigConst.tituloBoxModuloConsulta;
  tituloBoxModuloEmalote = ConfigConst.tituloBoxModuloEmalote;
  tituloBoxModuloRevisao = ConfigConst.tituloBoxModuloRevisao;

  Notificacoes: MatTableDataSource<any>;
  ColumnsNot = ['id_Notificacao', 'dataHora', 'assunto', 'texto'];
  @ViewChild('ordernarNotNLidos', { static: false }) ordernarNotNLidos: MatSort;
  @ViewChild('paginarNotNLidos', { static: false })
  paginarNotNLidos: MatPaginator;

  NotificacoesLidos: MatTableDataSource<any>;
  ColumnsNotLidos = ['id_Notificacao', 'dataHora', 'assunto', 'texto'];
  @ViewChild('ordernarNotLidos', { static: false }) ordernarNotLidos: MatSort;
  @ViewChild('paginarNotLidos', { static: false })
  paginarNotLidos: MatPaginator;
  module = '';

  validMenu() {
    if (window.location.pathname != '/dashboard') {
      this.showNavbarMenulateral = true;
      this.minimize = true;
    } else {
      this.showNavbarMenulateral = false;
      this.minimize = false;
    }
  }

  ngOnInit(): void {
    this.isImpostocerto = ConfigConst.APP === 'impostocerto';

    if (this.isImpostocerto) {
      this.pathAuditoria = ['/analise-consolidada', '/auditoria'];
    }

    this.showPathButtons = true;

    this.Descricao = localStorage.getItem('planoDescricao');

    this.isTributario =
      localStorage.getItem('email_login') === ConfigConst.emailTributario;

    this.isDemo =
      localStorage.getItem('email_login') === 'demo@impostograma.com.br';

    this.isSustentacao =
      localStorage.getItem('email_login') === ConfigConst.emailSustentacao ||
      this.isTributario ||
      this.isDemo;

    this.isGestorDemo =
      localStorage.getItem('cnpj_gestor') === '10755863000122';

    if (this.isValidRoute() == true) {
      this.List_Notificacao('TESTE');

      this.checaDadosGestor();

      if (localStorage.getItem('user_perfil') === '8') {
        this.compartilhado = true;
        const Modulos = localStorage.getItem('modulos_compartilhado');
        if (Modulos) {
          this.modulosDisponiveis = this.modulosDisponiveis.filter((el) =>
            Modulos.includes(el)
          );
        }
      }

      setInterval(() => {
        this.checaDadosGestor();
        this.List_Notificacao('TESTE');
      }, 180000);
    }

    this.Notificacoes = new MatTableDataSource();
    this.NotificacoesLidos = new MatTableDataSource();

    this.Notificacoes.sort = this.ordernarNotNLidos;
    this.Notificacoes.paginator = this.paginarNotNLidos;
    this.NotificacoesLidos.sort = this.ordernarNotLidos;
    this.NotificacoesLidos.paginator = this.paginarNotLidos;

    this.route.events.subscribe((e) => {
      this.pathName = window.location.pathname;
      // console.log("Página =================================>",window.location.pathname);
      if (
        window.location.pathname === '/auditoria' ||
        window.location.pathname === '/analise-consolidada' ||
        window.location.pathname.includes('/relatorio')
      ) {
        this.auditoria = true;
        this.revisao = false;
        this.consulta = false;
        this.segregacao = false;
        this.emalote = false;
        this.module = ConfigConst.modulo.auditoria;
        this.recuperacao = false;
        this.relatorios = false
      }
      if (window.location.pathname === '/revisao-tributaria') {
        this.auditoria = false;
        this.revisao = true;
        this.consulta = false;
        this.segregacao = false;
        this.emalote = false;
        this.module = ConfigConst.modulo.revisao;
        this.recuperacao = false;
        this.relatorios = false
      }
      if (
        window.location.pathname === '/consulta-livre-de-produtos' ||
        window.location.pathname === '/simulador-de-operacoes'
      ) {
        this.auditoria = false;
        this.revisao = false;
        this.consulta = true;
        this.segregacao = false;
        this.recuperacao = false;
        this.emalote = false;
        this.relatorios = false
        this.module = ConfigConst.modulo.consulta;
      }
      if (window.location.pathname === '/segregacao') {
        this.auditoria = false;
        this.revisao = false;
        this.consulta = false;
        this.segregacao = true;
        this.recuperacao = false;
        this.emalote = false;
        this.relatorios = false
        this.module = ConfigConst.modulo.segregacao;
      }
      if (
        window.location.pathname === '/emalote' ||
        window.location.pathname === '/visao-situacional' ||
        window.location.pathname === '/emalote-details'
      ) {
        this.auditoria = false;
        this.revisao = false;
        this.consulta = false;
        this.segregacao = false;
        this.emalote = true;
        this.module = ConfigConst.modulo.emalote;
        this.recuperacao = false;
        this.relatorios = false
      }

      if (window.location.pathname === '/recuperacao-simples-nacional') {
        this.auditoria = false;
        this.revisao = false;
        this.consulta = false;
        this.segregacao = false;
        this.emalote = false;
        this.recuperacao = true;
        this.relatorios = false
        this.module = ConfigConst.modulo.recuperacao;
      }

      if (window.location.pathname === '/relatorios-turbosped') {
        this.auditoria = false;
        this.revisao = false;
        this.consulta = false;
        this.segregacao = false;
        this.emalote = false;
        this.recuperacao = false;
        this.relatorios = true
        this.module = ConfigConst.modulo.relatorios;
      }

      if (
        window.location.pathname === '/minhas-contabilidade' ||
        window.location.pathname === '/usuario-contabilidade' ||
        window.location.pathname === '/meus-dados' ||
        window.location.pathname === '/financeiro' ||
        window.location.pathname === '/integracoes' ||
        window.location.pathname === '/plugin-captura'
      ) {
        this.showNavbarMenulateral = false;
        this.minimize = false;
      }
    });

    if (ConfigConst.baseURL.includes('impostograma')) {
      this.imposto = 0;
      this.imp = true;
      if (this.isValidRoute()) {
        this.ContratoService.getByIdContrato(
          localStorage.getItem('cnpj_gestor')
        ).subscribe((res) => {
          // console.log('aQUUIIIIIII========>',res[res.length-1]);
          if (res.body.length > 0) {
            this.dataInadinplencia = res.body[0]['DataInadinplencia'];
            this.dataFalhaPagamento = res.body[0]['DataFalhaPagamento'];
            this.dataPagamento = moment(res.body[0]['DataPagamento'])
              .add(6, 'hours')
              .toISOString();
            this.pagamentoPendente = res.body[0]['PagamentoPendente'];

            localStorage.setItem(
              'DataHoraContratacao',
              res.body[0]['DataHoraContratacao'] ?? ''
            );

            localStorage.setItem('Termo', res.body[0]['Termo']?.trim() ?? '');

            if (res.body[res.body.length - 1]['TipoPago'] == 'G') {
              this.tag = true;
              const now = new Date(); // Data de hoje
              // now.setDate(now.getDate()+46);
              const past = new Date(
                res.body[res.body.length - 1]['DataHoraContratacao']
              ); // Outra data no passado
              // const past = new Date(); // Outra data no passado
              past.setDate(past.getDate() + 45);
              const soma = Number(past.getTime()) - Number(now.getTime());
              const diff = Math.abs(soma); // Subtrai uma data pela outra
              const days = Math.ceil(diff / (1000 * 60 * 60 * 24)); // Divide o total pelo total de milisegundos correspondentes a 1 dia. (1000 milisegundos = 1 segundo).
              if (soma < 0) {
                this.days = Number(days) * -1;
              } else {
                this.days = days;
              }
              // console.log(past.getTime()+"-"+now.getTime()+'=' + this.days + ' dias');
            }
          }
        });
      }
    } else {
      this.imposto = 1;
      this.imp = false;
    }

    if (this.isValidRoute()) {
      if (window.location.pathname !== '/login') {
        this.MetricasUpload();
      }

      if (window.location.pathname === '/dashboard') {
        if (ConfigConst.APP === 'impostograma') {
          this.consultarplano();
        }
      }
      this.validMenu();
    }

    if (this.UserUtil.getPerfil() == '5') {
      this.nivel = true;
    }

    if (localStorage.getItem('full_gestor') == null) {
      this.selectedGestor = ConfigConst.SelecionarumaContabilidade;
    } else {
      this.selectedGestor = localStorage.getItem('full_gestor');
    }
    this.nivelConfig();

    //  this.configInitialData();
    this.eventComponent.localEvent.subscribe((res) => {
      if (res) {
        this.configInitialData();
        if (this.isValidRoute()) {
          this.showNavBarMenu = res;

          this.showPathButtons = this.isValidRouteToShowNavigator();

          this.validMenu();
        }
        this.nivelConfig();
      }
    });

    if (
      localStorage.getItem('full_gestor') == null ||
      this.selectedGestor == ConfigConst.SelecionarumaContabilidade
    ) {
      this.gestorstorageService.setGestorControl('true');

      if (this.showNavBarMenu) {
        if (
          this.isValidRoute() &&
          window.location.pathname.substr(0, 9) != '/download'
        ) {
          this.openDialog();
        }
      }
    }

    if (localStorage.getItem('modulo') !== '') {
      if (localStorage.getItem('modulo') === '2') {
        this.auditoria = true;
        this.revisao = false;
        this.consulta = false;
        this.segregacao = false;
        this.emalote = false;
        this.recuperacao = false;
        this.relatorios = false;
        this.module = '2';
      }
      if (localStorage.getItem('modulo') === '3') {
        this.auditoria = false;
        this.revisao = true;
        this.consulta = false;
        this.segregacao = false;
        this.emalote = false;
        this.recuperacao = false;
        this.relatorios = false;
        this.module = '3';
      }
      if (localStorage.getItem('modulo') === '5') {
        this.auditoria = false;
        this.revisao = false;
        this.consulta = true;
        this.segregacao = false;
        this.emalote = false;
        this.recuperacao = false;
        this.relatorios = false;

        this.module = '5';
      }
      if (localStorage.getItem('modulo') === '4') {
        this.auditoria = false;
        this.revisao = false;
        this.consulta = false;
        this.segregacao = true;
        this.emalote = false;
        this.recuperacao = false;
        this.relatorios = false;
        this.module = '4';
      }
      if (localStorage.getItem('modulo') === '1') {
        this.auditoria = false;
        this.revisao = false;
        this.consulta = false;
        this.segregacao = false;
        this.emalote = true;
        this.recuperacao = false;
        this.relatorios = false;
        this.module = '1';
      }

      if (localStorage.getItem('modulo') === '6') {
        this.auditoria = false;
        this.revisao = false;
        this.consulta = false;
        this.segregacao = false;
        this.recuperacao = true;
        this.emalote = false;
        this.relatorios = false;
        this.module = '6';
      }

      if (localStorage.getItem('modulo') === '7') {
        this.auditoria = false;
        this.revisao = false;
        this.consulta = false;
        this.segregacao = false;
        this.recuperacao = false;
        this.emalote = false;
        this.relatorios = true;
        this.module = '7';
      }
    }

    if (this.isValidRoute() && !localStorage.getItem('cnpj_gestor')) {
      return this.logout();
    }

    this.List_Notificacao('TESTE');

    setInterval(() => {
      this.checaDadosGestor();
      this.List_Notificacao('TESTE');
    }, 900000);
  }

  MetricasUpload(token = 'TESTE') {
    var usercompartilhado = 'false';

    if (localStorage.getItem('user_perfil') === '8') {
      usercompartilhado = 'true';
    }

    this.EmaloteService.CarregarGraficos(
      '7',
      null,
      usercompartilhado
    ).subscribe((retorno) => {
      if (retorno['status']['resultado'] == false) {
        this.MessageService.showMessage(
          retorno['status']['mensagem'],
          'error-snackbar'
        );
      } else {
        // console.log("AQUI DOC 7",retorno);
        this.metrica = retorno['data'];

        localStorage.setItem(
          'DataHoraUltimaAtualizacao',
          retorno['data']['DataHoraCalculo']?.substring(0, 16)
        );

        // retorno['data']['GbTotal'] = 2;
        // retorno['data']['GbUso'] = 0.2;
        this.DataHoraUltimaAtualizacao = retorno['data'][
          'DataHoraCalculo'
        ]?.substring(0, 16);

        this.NFSCapturadas = retorno['data']['NFS_Capturada'];
        this.ProdutosRevisados = retorno['data']['ProdutosRevisados'] ?? 0;
        this.ProdutosRevisadosContratado =
          retorno['data']['ProdutosRevisadosContratado'] ?? 0;

        const quantidadeRestantes =
          this.ProdutosRevisadosContratado - this.ProdutosRevisados;

        localStorage.setItem(
          'quantidade_associacoes_restantes',
          quantidadeRestantes < 0 ? '0' : String(quantidadeRestantes)
        );

        this.CTE = Number(
          (Number(retorno['data']['KbCTE']) +
            Number(retorno['data']['KbCTECadEmit']) +
            Number(retorno['data']['KbCTEEVE'])) /
            1000
        ).toFixed(2);
        this.NFC = Number(
          (Number(retorno['data']['KbNFC']) +
            Number(retorno['data']['KbNFCEVE'])) /
            1000
        ).toFixed(2);
        this.NFE = Number(
          (Number(retorno['data']['KbNFE']) +
            Number(retorno['data']['KbNFECadEmit']) +
            Number(retorno['data']['KbNFEEVE'])) /
            1000
        ).toFixed(2);
        this.NFS = Number(retorno['data']['KbNFS'] / 1000).toFixed(2);
        this.SAT = Number(
          (Number(retorno['data']['KbSAT']) +
            Number(retorno['data']['KbSATCan'])) /
            1000
        ).toFixed(2);
        this.Inut = Number(retorno['data']['KbInut']).toFixed(2);

        this.dashboardService.GbTotal = retorno['data']['GbTotal'];
        // this.GbTotal = 1;
        this.KBTotal = Number(
          Number(Number(this.dashboardService.GbTotal) / 1024) / 1024
        );
        this.dashboardService.GbUso = retorno['data']['GbUso'];
        this.dashboardService.value = String(
          (Number(retorno['data']['GbUso']) * 100) /
            this.dashboardService.GbTotal
        );

        this.valueCTE = String(
          Number((Number(this.CTE) * 100) / Number(this.KBTotal))
        );
        // console.log("Number(("+Number(this.CTE)+" * 100) / Number("+this.KBTotal+"))) = "+ this.valueCTE);

        this.valueNFC = String(
          Number((Number(this.NFC) * 100) / Number(this.KBTotal))
        );
        this.valueNFE = String(
          Number((Number(this.NFE) * 100) / Number(this.KBTotal))
        );
        this.valueNFS = String(
          Number((Number(this.NFS) * 100) / Number(this.KBTotal))
        );
        this.valueSAT = String(
          Number((Number(this.SAT) * 100) / Number(this.KBTotal))
        );
        this.valueInut = String(
          Number((Number(this.Inut) * 100) / Number(this.KBTotal))
        );

        this.ProdutosRevisadosIA = retorno['data']['ProdutosRevisadosIA'];
        this.ProdutosRevisadosTime = retorno['data']['ProdutosRevisadosTime'];
        this.ProdutosRevisadosMes = retorno['data']['ProdutosRevisadosMes'];
      }
    });

    // console.log(settings);
  }

  handleInfoNFS() {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const dialogRef = this.dialog.open(HelpDialogComponent, {
      width: '90vw',
      maxWidth: '600px',
      maxHeight: '90vh',
      data: {
        title: 'Info NFS!',
        message: `Quantidade de NFS capturadas em ${
          now.getMonth() + 1
        }/${now.getFullYear()}: ${this.NFSCapturadas}`,
        useHtml: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.menuGigasTrigger.openMenu();
    });
  }

  ngAfterViewInit() {
    this.Notificacoes.sort = this.ordernarNotNLidos;
    this.Notificacoes.paginator = this.paginarNotNLidos;
    this.NotificacoesLidos.sort = this.ordernarNotLidos;
    this.NotificacoesLidos.paginator = this.paginarNotLidos;
  }

  openNewWindow(): void {
    const link = this.document.createElement('a');
    link.target = '_blank';
    link.href = 'https://www.impostograma.com.br/financeiro';
    link.click();
    link.remove();
  }

  openSideNav() {
    this.sidenav.open();
  }

  closeSideNav() {
    this.sidenav.close();
  }

  logout() {
    this.showNavBarMenu = false;
    this.authService.logout();
  }

  openDialogo() {
    this.loading = true;

    this.GestorUsuarioService.getGestorByEmail(
      btoa(localStorage.getItem('email_login'))
    )
      .subscribe({
        next: (gestores) => {
          if (gestores.body.message) {
            this.messageService.showMessage(
              gestores.body.message,
              'warning-snackbar'
            );
          }

          const dialogRef = this.dialog.open(DialogModalComponent, {
            width: '80vw',
            maxWidth: '500px',
            maxHeight: '90vh',
            data: {
              title: 'Selecionar ' + ConfigConst.Contabilidade,
              subtitle: 'Selecionar ' + ConfigConst.Contabilidade,
              text: localStorage.getItem('full_gestor'),
              gestores: gestores.body,
              emailLogin: localStorage.getItem('email_login'),
            },
          });
          dialogRef.afterClosed().subscribe((result) => {});
        },
      })
      .add(() => {
        this.loading = false;
      });
  }

  handleOpenNotifications() {
    this.menuNotific.openMenu();
    this.notificacoesNovasVisualizadas = true;

    this.titleService.setTitle(ConfigConst.APP);
  }

  openDialog() {
    this.GestorUsuarioService.getGestorByEmail(
      btoa(localStorage.getItem('email_login'))
    ).subscribe({
      next: (gestores) => {
        if (gestores.body.message) {
          this.messageService.showMessage(
            gestores.body.message,
            'warning-snackbar'
          );
        }
        if (gestores.body.length > 0) {
          if (
            localStorage.getItem('full_gestor') == null ||
            this.selectedGestor ==
              'Selecione ' + ConfigConst.umaContabilidade + '!'
          ) {
            const dialogRef = this.dialog.open(DialogModalComponent, {
              width: '80vw',
              maxWidth: '500px',
              maxHeight: '90vh',
              data: {
                title: ' Bem-Vindo',
                subtitle: 'Selecione ' + ConfigConst.umaContabilidade,
                text: localStorage.getItem('full_gestor'),
                gestores: gestores.body,
                emailLogin: localStorage.getItem('email_login'),
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              document.location.reload();
            });
          }
        } else {
          this.messageService.showMessage(
            ConfigConst.continuarCadastrar,
            'warning-snackbar'
          );
          setTimeout(() => {
            const dialogRef = this.dialog.open(
              AdicionarcontabilidadeComponent,
              {
                width: '80vw',
                maxWidth: '800px',
                maxHeight: '90vh',
                data: {},
              }
            );
            dialogRef.afterClosed().subscribe((result) => {
              if (this.isValidRoute()) {
                this.openDialog();
              }
            });
          }, 2000);
        }
      },
      error: (err) => {
        if (err.error.status)
          this.messageService.showMessage(err.error.status, 'warning-snackbar');
      },
    });
  }

  isValidRoute() {
    let urlRoute = window.location.pathname;
    this.validMenu();
    if (
      urlRoute !== '/' &&
      urlRoute !== '/login' &&
      urlRoute !== '/nova-senha' &&
      urlRoute !== '/recuperar-senha' &&
      urlRoute !== '/autenticar'
    ) {
      return true;
    } else {
      return false;
    }
  }

  isValidRouteToShowNavigator() {
    let urlRoute = window.location.pathname;

    if (!urlRoute.includes('relatorio')) {
      return true;
    } else {
      return false;
    }
  }

  formatarCNPJ() {
    const cnpjspit = this.selectedGestor.split(' - ');
    return `${CustomValidators.formatarCNPJ(cnpjspit[0])} - ${
      cnpjspit[1]
    }`.substring(0, 30);
  }

  setSelectedGestor(event) {
    this.selectedGestor = event.value.CNPJ_Gestor + ' - ' + event.value.Nome;
    document.location.reload();
  }

  change(event) {
    if (event.isUserInput) {
      this.gestorToWork = event.source.value.CNPJ_Gestor;
      this.gestorName = event.source.value.Nome;
      localStorage.setItem('cnpj_gestor', this.gestorToWork);
      localStorage.setItem(
        'full_gestor',
        `${this.gestorToWork} - ${this.gestorName}`
      );
      //this.selectedGestor = `${this.gestorToWork} - ${this.gestorName}`;
      this.eventComponent.setEvent(true);
      //this.router.navigate(['/dashboard']);
      document.location.reload();
    }
  }

  getGestores() {
    this.gestorUsuarioService
      .getGestorByEmail(btoa(this.userUtil.getEmailLogin()))
      .subscribe({
        next: (gestores) => {
          if (gestores.status) {
            if (this.userUtil.getCnpjGestor() !== null) {
              this.messageService.showMessage(
                gestores.status,
                'warning-snackbar'
              );
            }
          }
          this.gestores = gestores;
        },
        error: (err) => {
          if (err.error.status)
            this.messageService.showMessage(
              err.error.status,
              'warning-snackbar'
            );
        },
      });
  }

  private nivelConfig() {
    this.nivel = localStorage.getItem('user_perfil') == '5' ? true : false;
  }

  /*
   * Metodo utulizado para realizar a configuracao inicial dos
   * dados necessarios para o funcionamento dos componentes
   *
   * */
  configInitialData() {
    this.usuario = this.userUtil.getEmailLogin();
    this.gestorToWork = this.userUtil.getCnpjGestor();
    this.fullGestor = this.userUtil.getFullGestor();
    //this.getGestores();
    if (this.fullGestor !== null) this.selectedGestor = this.fullGestor;
    else
      this.selectedGestor = 'Selecione ' + ConfigConst.umaContabilidade + '!';
  }

  contratos: any[] = [];

  consultarplano() {
    localStorage.removeItem('id_contrato');
    localStorage.removeItem('identificador_contrato');
    localStorage.removeItem('finaltrial_contrato');
    localStorage.removeItem('efetivar_contrato');
    localStorage.removeItem('trial_contrato');
    localStorage.removeItem('inadimplente');

    this.PagamentoService.getClienteEmail(
      localStorage.getItem('email_login')
    ).subscribe((res) => {
      if (res.length > 0) {
        // DEFINIR ID SUPER
        localStorage.setItem('id_super', res[0]['id_sacado_sac']);
        //PEGA O ID DO CONTRATO
        this.PagamentoService.getContrato(res[0]['id_sacado_sac']).subscribe(
          (result) => {
            // console.log(result);
            for (
              var j = 0, length = result[0]['data'].length;
              j < length;
              j++
            ) {
              // console.log(result[0]['data'][j]);
              var inadimplente = result[0]['data'][j]['inadimplente'];
              var idcontrato = result[0]['data'][j]['id_planocliente_plc'];
              var identificador = result[0]['data'][j][
                'st_identificador_plc'
              ].substring(0, 14);
              var finaltrial = result[0]['data'][j]['dt_trialfim_plc'];
              var efetivar = result[0]['data'][j]['fl_primeiropag_plc'];
              var trial = result[0]['data'][j]['fl_trial_plc'];
              this.contratos[identificador] = [];
              this.contratos[identificador].push(idcontrato);
              this.contratos[identificador].push(identificador);
              this.contratos[identificador].push(finaltrial);
              this.contratos[identificador].push(efetivar);
              this.contratos[identificador].push(trial);
              this.contratos[identificador].push(inadimplente);
            }

            if (
              this.contratos.hasOwnProperty(localStorage.getItem('cnpj_gestor'))
            ) {
              // console.log(this.contratos[localStorage.getItem('cnpj_gestor')]);
              localStorage.setItem(
                'id_contrato',
                this.contratos[localStorage.getItem('cnpj_gestor')][0]
              );
              localStorage.setItem(
                'identificador_contrato',
                this.contratos[localStorage.getItem('cnpj_gestor')][1]
              );
              localStorage.setItem(
                'finaltrial_contrato',
                this.contratos[localStorage.getItem('cnpj_gestor')][2]
              );
              localStorage.setItem(
                'efetivar_contrato',
                this.contratos[localStorage.getItem('cnpj_gestor')][3]
              );
              localStorage.setItem(
                'trial_contrato',
                this.contratos[localStorage.getItem('cnpj_gestor')][4]
              );
              localStorage.setItem(
                'inadimplente',
                this.contratos[localStorage.getItem('cnpj_gestor')][5]
              );
            } else {
              localStorage.setItem(
                'id_contrato',
                result[0]['data'][0]['id_planocliente_plc']
              );
              localStorage.setItem(
                'identificador_contrato',
                result[0]['data'][0]['st_identificador_plc'].substring(0, 14)
              );
              localStorage.setItem(
                'finaltrial_contrato',
                result[0]['data'][0]['dt_trialfim_plc']
              );
              localStorage.setItem(
                'efetivar_contrato',
                result[0]['data'][0]['fl_primeiropag_plc']
              );
              localStorage.setItem(
                'trial_contrato',
                result[0]['data'][0]['fl_trial_plc']
              );
              localStorage.setItem(
                'inadimplente',
                result[0]['data'][0]['inadimplente']
              );
            }
          }
        );
      }
    });
  }

  cobrança() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '70vw',
      maxWidth: '800px',
      maxHeight: '90vh',
      data: {
        title: 'AVISO!',
        message: 'Você possui pendencias de pagamento, Deseja regularizá-las?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        window.location.href = '/financeiro';
      }
    });
  }

  changesizefull() {
    $('.menulateral span').attr({ style: '' });
    $('.menuinactive').attr({ style: '' });
    $('.container-visible-button').css({ display: 'none' });
    $('.menulateral button').removeClass('text-center');
    $('.mat-drawer-content').addClass('open_menu');
    $('.sub').addClass('submenu');
  }

  changesizesmall() {
    $('.menulateral span').css({ display: 'none' });
    $('.menuinactive').css({ display: 'none' });
    $('.container-visible-button').css({ display: '' });
    $('.menulateral button').addClass('text-center');
    $('.mat-drawer-content').addClass('close_menu');
    $('.sub').removeClass('submenu');
  }

  fecharmenu() {
    $('.menulateral span').css({ display: 'none' });
    $('.menuinactive').css({ display: 'none' });
    $('.container-visible-button').css({ display: '' });
    $('.menulateral button').addClass('text-center');
    // $('.mat-drawer-content').css({'margin-left':'57px'});
    $('.sub').removeClass('submenu');
    if (this.closed) {
      $('.mat-drawer-content').animate({ 'margin-left': '0px' }, 250);
      $('.menulateral').animate({ 'margin-left': '-80px' }, 500);
      this.closed = false;
    } else {
      $('.mat-drawer-content').animate({ 'margin-left': '57px' }, 250);
      $('.menulateral').animate({ 'margin-left': '0px' }, 500);
      this.closed = true;
    }
  }

  clique() {
    this.closed = false;
    this.fecharmenu();
    $('#over').fadeOut();
  }

  handleButtonAnalytics(action: string) {
    this.googleAnalyticsService.sendEmitter({
      eventAction: action,
      eventCategory: 'Botão',
      eventLabel: CustomValidators.validarModulo(),
    });
  }

  acessoAnalytics(action: string) {
    this.googleAnalyticsService.sendEmitter({
      eventAction: action,
      eventCategory: 'Botão',
      eventLabel: CustomValidators.validarModulo(),
    });
  }

  upgrade() {
    this.dialog.open(UpgradeModalComponent, {
      width: '80vw',
      maxWidth: '500px',
      maxHeight: '90vh',
      data: {
        title: 'Aviso!',
        subtitle: ConfigConst.SubTituloUpgrade,
        text: ConfigConst.TextoUpgrade,
      },
    });
  }

  // notifications
  notificacao = [];
  num_notificacao = 0;

  List_Notificacao(token: string) {
    this.EmaloteService.ListaNotificacao(token).subscribe((retorno) => {
      // console.log("NOTIFICACAO ===================>", retorno);

      this.Notificacoes.data = [];
      this.NotificacoesLidos.data = [];
      this.notificacao = [];

      this.notificacoesNovas = 0;

      if (retorno['data'] !== null) {
        if (
          retorno['data'].hasOwnProperty('notificacaoLista') &&
          retorno['data']['notificacaoLista'] !== null
        ) {
          let notific = retorno['data']['notificacaoLista'];

          notific.forEach((el) => {
            if (el['lido'] == '0') {
              if (el['Entregue'] !== '1') {
                this.notificacoesNovas++;
              }
              this.Notificacoes.data.push(el);
              this.Notificacoes.data = this.Notificacoes.data.slice();
              this.Notificacoes.sort = this.ordernarNotNLidos;
              this.Notificacoes.paginator = this.paginarNotNLidos;
              this.notificacao.push(el);
            } else {
              this.NotificacoesLidos.data.push(el);
              this.NotificacoesLidos.data = this.NotificacoesLidos.data.slice();
              this.NotificacoesLidos.sort = this.ordernarNotLidos;
              this.NotificacoesLidos.paginator = this.paginarNotLidos;
            }
          });

          this.num_notificacao = this.notificacao.length;
          this.notificacao = this.notificacao.slice(
            Math.max(this.notificacao.length - 7, 0)
          );
          this.iconnotifica = 'notifications';

          if (this.notificacoesNovas > 0) {
            this.notificacoesNovasVisualizadas = false;

            if (this.isSustentacao) {
              setTimeout(() => {
                this.messageService.showMessageNotification(
                  `Existe ${this.notificacoesNovas} nova(s) notificações para você!`,
                  'message-snackbar'
                );
              }, 1000);
            }
          }

          // console.log('Não Lidas',this.Notificacoes);
          // console.log('Lidas',this.NotificacoesLidos);
        }
      } else {
        //zera notificação
        this.Notificacoes.data = [];
        this.Notificacoes.data = this.Notificacoes.data.slice();
        this.Notificacoes.sort = this.ordernarNotNLidos;
        this.Notificacoes.paginator = this.paginarNotNLidos;

        this.NotificacoesLidos.data = [];
        this.NotificacoesLidos.data = this.NotificacoesLidos.data.slice();
        this.NotificacoesLidos.sort = this.ordernarNotLidos;
        this.NotificacoesLidos.paginator = this.paginarNotLidos;

        this.notificacao = [];
        this.num_notificacao = 0;
        this.iconnotifica = 'notifications_none';
      }
    });
  }

  showmodal(modal: string) {
    $('#over').fadeIn();
    $('.full').fadeIn();
    $('.modalNotific').fadeIn(800);
  }

  hidemodal() {
    $('#over').fadeOut();
    $('.full').fadeOut();
    $('.modalNotific').fadeOut();
    $('.modals').fadeOut();
    $('.modalNotific .collapse').each(function (index) {
      $(this).removeClass('show');
    });
    $('.modals .collapse').each(function (index) {
      $(this).removeClass('show');
    });
  }

  apFilterNotNLidos(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.Notificacoes.filter = filterValue.trim().toLowerCase();
  }

  apFilterNotLidos(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.NotificacoesLidos.filter = filterValue.trim().toLowerCase();
  }

  atualizarCor(
    produtosRevisados: number,
    produtosRevisadosContratado: number
  ): string {
    if (produtosRevisados >= produtosRevisadosContratado * 0.9) {
      return 'vermelho';
    } else {
      return 'primario';
    }
  }

  calcularPorcentagem(
    produtosRevisados: number,
    produtosRevisadosContratado: number
  ): number {
    return (produtosRevisados / produtosRevisadosContratado) * 100;
  }

  MarcarLido(row, token = 'TESTE') {
    function getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value);
    }

    let chave = getKeyByValue(this.Notificacoes.data, row);

    this.EmaloteService.UpdateNotificacaoLida(
      token,
      row['id_Notificacao']
    ).subscribe((retorno) => {
      console.log('UPDATE NOTIFICACÃO ===========>', retorno);
      if (retorno['status']['resultado'] == false) {
        this.MessageService.showMessage(
          retorno['status']['mensagem'],
          'error-snackbar'
        );
      } else {
        this.NotificacoesLidos.data.push(this.Notificacoes.data[chave]);
        this.NotificacoesLidos.data = this.NotificacoesLidos.data.slice();
        this.NotificacoesLidos.sort = this.ordernarNotLidos;
        this.NotificacoesLidos.paginator = this.paginarNotLidos;

        this.Notificacoes.data.splice(Number(chave), 1);
        this.Notificacoes.data = this.Notificacoes.data.slice();
        this.Notificacoes.sort = this.ordernarNotNLidos;
        this.Notificacoes.paginator = this.paginarNotNLidos;

        this.num_notificacao = this.Notificacoes.data.length;
        this.notificacao = this.Notificacoes.data.slice(
          Math.max(this.Notificacoes.data.length - 5, 1)
        );

        if (this.num_notificacao > 0) {
          this.iconnotifica = 'notifications';
        } else {
          this.iconnotifica = 'notifications_none';
        }
      }
    });
  }

  MarcarLidoTodas() {
    if (this.num_notificacao === 0) {
      return this.MessageService.showMessage(
        'Não há notificações pendentes!',
        'warning-snackbar'
      );
    }

    this.EmaloteService.UpdateNotificacaoLidaTodas().subscribe((retorno) => {
      if (retorno['status']['resultado'] == false) {
        this.MessageService.showMessage(
          retorno['status']['mensagem'],
          'error-snackbar'
        );
      } else {
        this.Notificacoes.data.forEach((el) => {
          this.NotificacoesLidos.data.push(el);
        });

        this.NotificacoesLidos.data = this.NotificacoesLidos.data.slice();
        this.NotificacoesLidos.sort = this.ordernarNotLidos;
        this.NotificacoesLidos.paginator = this.paginarNotLidos;

        this.Notificacoes.data = [];
        this.Notificacoes.data = this.Notificacoes.data.slice();
        this.Notificacoes.sort = this.ordernarNotNLidos;
        this.Notificacoes.paginator = this.paginarNotNLidos;

        this.num_notificacao = this.Notificacoes.data.length;
        this.notificacao = this.Notificacoes.data.slice(
          Math.max(this.Notificacoes.data.length - 5, 1)
        );

        if (this.num_notificacao > 0) {
          this.iconnotifica = 'notifications';
        } else {
          this.iconnotifica = 'notifications_none';
        }
      }
    });
  }

  checaDadosGestor() {
    this.GestorService.getByIdGestor(
      localStorage.getItem('cnpj_gestor')
    ).subscribe(
      (res) => {
        // console.log(res);
        if (res.body.length > 0) {
          localStorage.setItem('planoDescricao', res.body[0]['Descricao']);
          this.Descricao = res.body[0]['Descricao'];
          if (
            res.body[0]['Endereco'] == '' ||
            res.body[0]['Nro'] == null ||
            res.body[0]['Cidade'] == ''
          ) {
            if (this.dialog.openDialogs && this.dialog.openDialogs.length > 0) {
              return false;
            }
            const dialogRef = this.dialog.open(
              AtualizarcontabilidadeComponent,
              {
                width: '90vw',
                maxWidth: '800px',
                maxHeight: '90vh',
                data: {
                  cnpjs: localStorage.getItem('cnpj_gestor'),
                  title: 'Atualização de Dados Cadastrais',
                  subtitle:
                    'por favor verifique se os seus dados estão corretos antes de prosseguir',
                  consultaCNPJ: true,
                },
              }
            );
            dialogRef.afterClosed().subscribe((result) => {
              this.checaDadosGestor();
            });
          }
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }
}
